import styles from "./index.module.scss";
import { CssButton } from "../../../components/Buttons";
import Avatar from "../../../components/Avatars";
import {
  Bold,
  Card,
  CardBlock,
  CardFooter,
  CardHeader,
} from "../../../components/Card";
import Panel from "../../../components/Panel";
import Section from "../../../components/Section";
import { useRef } from "react";
import { useImmer } from "use-immer";
import { blockScroll } from "../../../components/Scroll";
import { ComicStoryChapter1 } from "../../../components/Comics/Stories";

const CozyPenguins = () => {
  document.title = "Cozyverse | Cozy Penguins";

  const ref = useRef(null);

  const [comicState, setComicState] = useImmer<{
    opened: boolean;
  }>({
    opened: false,
  });

  return (
    <div className={styles.root} ref={ref}>
      <Section className={styles.section1}>
        <Panel
          img="/assets/images/collection/cozypenguins/1/1_panel.png"
          title="Cozy Penguins"
          subtitle="The Origin Story"
          button={
            <CssButton
              text="Read now"
              onClick={() => {
                setComicState((state) => {
                  blockScroll();
                  state.opened = true;
                });
              }}
            />
          }
        />
        <Avatar
          img="/assets/images/collection/cozypenguins/1/peng2.png"
          hideMobile
          style={{
            width: "500px",
            bottom: "-1rem",
            right: "-4rem",
          }}
        />
        <Avatar
          img="/assets/images/collection/cozypenguins/1/prop1.png"
          hideMobile
          style={{
            width: "100px",
            top: "15%",
            right: "20%",
          }}
        />
        <Avatar
          img="/assets/images/collection/cozypenguins/1/prop2.png"
          hideMobile
          style={{
            width: "100px",
            top: "45%",
            right: "10%",
          }}
        />
        <Avatar
          img="/assets/images/collection/cozypenguins/1/prop3.png"
          hideMobile
          style={{
            width: "100px",
            top: "50%",
            left: "10%",
          }}
        />
        <Avatar
          img="/assets/images/collection/cozypenguins/1/prop4.png"
          hideMobile
          style={{
            width: "100px",
            top: "20%",
            left: "15%",
          }}
        />
        <ComicStoryChapter1
          open={comicState.opened}
          setOpen={(open) => {
            setComicState((state) => {
              state.opened = open;
            });
          }}
        />
      </Section>
      <Section className={styles.section2}>
        <Card
          header={
            <CardHeader>
              <img
                src="/assets/images/collection/cozypenguins/2/cozy_penguins.png"
                style={{
                  width: "40%",
                  position: "relative",
                  top: "2rem",
                }}
              />
            </CardHeader>
          }
          footer={
            <CardFooter>
              <div className={styles.adoptBanner}>
                <img src="/assets/images/collection/cozypenguins/2/adoptBanner.png" />
                <div className={styles.buttons}>
                  <div
                    className={styles.button}
                    onClick={() => {
                      window.open(
                        "https://pro.opensea.io/collection/cozy-penguin",
                        "_blank",
                      );
                    }}
                  >
                    <img src="/assets/images/collection/cozypenguins/2/btn_os.png" />
                    <div className={styles.text}>OpenSea</div>
                  </div>
                  <div
                    className={styles.button}
                    onClick={() => {
                      window.open(
                        "https://blur.io/collection/cozy-penguin",
                        "_blank",
                      );
                    }}
                  >
                    <img src="/assets/images/collection/cozypenguins/2/btn_blur.png" />
                    <div className={styles.text}>Blur</div>
                  </div>
                </div>
              </div>
            </CardFooter>
          }
        >
          <CardBlock>
            <div style={{ paddingTop: "3rem" }}>
              <Bold>10,000 Cozy Penguins</Bold> have found their way to the Cozy
              Reef, a paradise island hidden away on planet Ether. Be Careful!
              These adorable penguins will demand your attention and want you to
              play games with them in the Cozyverse.
            </div>
          </CardBlock>
          <CardBlock>
            <img src="/assets/images/collection/cozypenguins/2/adopt.png" />
          </CardBlock>
        </Card>
        <Avatar
          img="/assets/images/collection/cozypenguins/2/peng1.png"
          hideMobile
          style={{
            width: "500px",
            bottom: "-1rem",
            left: "-6rem",
            zIndex: "2",
          }}
        />
      </Section>
      <Section className={styles.sectionExplorers}>
        <Card
          header={
            <CardHeader>
              <div className={styles.logo}>
                <img src="/assets/images/collection/cozypenguins/cpexplorers/cp_explorers.png" />
              </div>
            </CardHeader>
          }
          footer={
            <CardFooter>
              <div
                className={styles.adoptBanner}
                style={{ paddingTop: "60px" }}
              >
                <div className={styles.buttons}>
                  <div
                    className={styles.button}
                    onClick={() => {
                      window.open(
                        "https://salvor.io/collections/0x388a5d40d53fc84186ec680a43b906b9f8f010f1",
                        "_blank",
                      );
                    }}
                  >
                    <img src="/assets/images/collection/cozypenguins/cpexplorers/btn_salvor.png" />
                    <div className={styles.text}>Salvor</div>
                  </div>
                  <div
                    className={styles.button}
                    onClick={() => {
                      window.open(
                        "https://avax.hyperspace.xyz/collection/avax/cozy-penguin-explorers",
                        "_blank",
                      );
                    }}
                  >
                    <img src="/assets/images/collection/cozypenguins/cpexplorers/btn_hs.png" />
                    <div className={styles.text}>Hyperspace</div>
                  </div>
                </div>
              </div>
            </CardFooter>
          }
        >
          <div className={styles.wrapper}>
            <CardBlock>
              Cozy Penguins have geared up for their next adventure. The bravest
              Cozys jumped through the portal on the Cozy reef and found
              themselves in an unknown snowy land. They're setting up camp, and
              will begin exploring this region soon.
            </CardBlock>
          </div>
        </Card>
        <Avatar
          img="/assets/images/home/1/penguin.png"
          className={styles.penguin}
        />
        <Avatar img="/assets/images/home/1/duck.png" className={styles.duck} />

        <Avatar
          img="/assets/images/home/1/snow_f.png"
          className={styles.snowfront}
        />
      </Section>

      <Section className={styles.section3}>
        <Card
          header={
            <CardHeader>
              <img
                src="/assets/images/collection/cozypenguins/3/gasha.png"
                style={{
                  position: "relative",
                  bottom: "68px",
                }}
              />
            </CardHeader>
          }
        >
          <CardBlock>
            Each <Bold>Cozy Penguin</Bold> is uniquely generated from several
            hundred traits, represented as ERC721 token on Ethereum, with a few
            unique penguins mixed in. The metadata is hosted on Arweave. Cozy
            Penguins were initially free to adopt, just requiring as fees to
            mint.
          </CardBlock>
          <CardBlock>
            <div style={{ display: "block", paddingTop: "2rem" }}>
              {" "}
              Verified Address:{" "}
            </div>
            <Bold>0x63d48Ed3f50aBA950c1 7e37CA03356CCd6b6a280</Bold>
          </CardBlock>
        </Card>
        <Avatar
          img="/assets/images/collection/cozypenguins/3/cp_r.png"
          hideMobile
          style={{
            position: "absolute",
            bottom: "-3rem",
            right: "-2rem",
            zIndex: "15",
          }}
        />
        <Avatar
          img="/assets/images/collection/cozypenguins/3/cp_l.png"
          hideMobile
          style={{
            position: "absolute",
            bottom: "-4rem",
            left: "-9rem",
            zIndex: "15",
          }}
        />
      </Section>
      <Section className={styles.section4}>
        <Card
          header={
            <CardHeader>
              <div className={styles.title}>FAQ</div>
            </CardHeader>
          }
        >
          <div style={{ paddingTop: "4rem" }}>
            <CardBlock>
              <Bold>What are Cozy Penguins?</Bold>
              <br />
              Cozy Penguins are a 10,000 NFT collection on the Ethereum
              Blockchain
            </CardBlock>
            <CardBlock>
              <Bold>When did Cozy Penguins Launch?</Bold>
              <br />
              Cozy Penguins launched in February 2022 as a mint free. Users only
              had to pay gas fees.
            </CardBlock>
            <CardBlock>
              <Bold>What do I do with Cozy Penguins?</Bold>
              <br />
              Cozy Penguins are a digital collectible and are part of the core
              identity of the Cozy Community. Cozy Penguins are featured in our
              games and lore, and will be required for certain blockchain
              adventures.
            </CardBlock>
            <CardBlock>
              <Bold>Should I buy a Cozy Penguin?</Bold>
              <br />
              That's totally up to you. The Cozy Community welcomes holders and
              non-holders alike!
            </CardBlock>
          </div>
        </Card>
        <Avatar
          img="/assets/images/collection/cozypenguins/4/pengu.png"
          hideMobile
          style={{
            width: "14%",
            bottom: "-1rem",
            left: "50%",
            "-ms-transform": "translateX(-50%)",
            transform: "translateX(-50%)",
            zIndex: "15",
          }}
        />
        <Avatar
          img="/assets/images/collection/cozypenguins/4/ducks.png"
          hideMobile
          style={{
            bottom: "-6rem",
            left: "-16rem",
            zIndex: "15",
          }}
        />
        <Avatar
          img="/assets/images/collection/cozypenguins/4/ducks.png"
          hideMobile
          style={{
            bottom: "-6rem",
            right: "-16rem",
            zIndex: "15",
          }}
        />
      </Section>
    </div>
  );
};

export default CozyPenguins;
